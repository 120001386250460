import React, { useState, useEffect } from "react";
import Layout from "../components/layout/layout";
import cookies from "next-cookies";
import { useDispatch } from "react-redux";
import { wrapper } from "../store";
import { GetServerSideProps, NextPage } from "next";
import { gloablTokenValidation } from "../src/lib/auth";
import { JWT_COOKIE_KEY } from "../src/constant/key.constant";
import MainIndex from "../components/main/mainIndex";

const Index: NextPage = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Layout title="Market Wizards" navActive={0} classList="main">
      <MainIndex />
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>마켓위자드 서비스 종료 안내</h2>
            <p>
              <img src="https://marketwizard-prod.s3.ap-northeast-2.amazonaws.com/mw_end_notice.png"></img>
            </p>
            <button onClick={closePopup}>닫기</button>
          </div>
        </div>
      )}
      <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000; /* Adjust this value as needed */
        }

        .popup-inner {
          background: white;
          padding: 20px;
          border-radius: 8px;
          text-align: center;
          z-index: 1001; /* Ensure it is above the popup backdrop */
          max-height: 80vh;
          overflow-y: auto;
        }

        .popup-inner > p > img {
          max-width: 600px;
          height: auto;
        }

        button {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          color: #642ad6;
          font-size: 16px;
          font-weight: 500;
          border: 0px;
          cursor: pointer;
          margin: auto;
          background-image: url(/asset/image/survey/answer_bg.jpg);
          background-repeat: repeat-x;
          background-size: 1px 101%;
          color: #fff;
        }
      `}</style>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(
  async (context) => {
    const token = cookies(context)[JWT_COOKIE_KEY];
    if (token) {
      await gloablTokenValidation(`${token}`, context);
    }

    return {};
  }
);

export default Index;
