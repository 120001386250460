import styled from 'styled-components';
import Link from 'next/link';
import {
  bgColor2d,
  bgColorFFF,
  textLightYellow,
  fontSize_40,
  fontSize_16,
  fontSizeL,
  fontSizeM,
  fontSizeXXXL,
  fontSizeXXXXL,
  fontSize_46,
  textColorFFF,
  textColorMain,
  textColorTheme,
} from '../../src/constant/css.constant';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container01: {
      width: '100%',
      color: textColorFFF,
      zIndex: 1,
      textAlign: 'center',
      position: 'relative',
    },
    gridBox: {
      height: '100%',
    },
  })
);

const BackgroundTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 0;
  height: 600px;
`;
const BackgroundBlock = styled.div`
  position: absolute;
  top: 600px;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: ;
  height: 900px;
  background-color: ${bgColor2d};

  @media (max-width: 1199.98px) {
    height: 1520px;
  }
`;

const Container01 = styled.div`
  // max-width: 1100px;
  margin: auto;
  max-width: 378px;
  padding: 190px 0px 100px;
  height: 720px;
  position: relative;
  z-index: 1;
`;
const Container02 = styled.div`
  // margin: auto;
  padding: 100px 10px 80px 10px;
  // height: 900px;
  position: relative;
  z-index: 1;
  background-color: #2d2d2d;

  .content {
    max-width: 1100px;
    margin: 0 auto;
  }
  .c02_content {
    display: flex;
    width: 100%;

    div {
      flex: 1;
    }
  }

  .down_btn {
    width: 100%;
    padding-top: 150px;
    text-align: center;

    img {
      cursor: pointer;
    }
    img:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 800px) {
    .section2_title > div {
      font-size: ${fontSize_40};
    }
  }

  @media (max-width: 1199.98px) {
    // height: 1520px;

    .down_btn {
      padding-top: 50px;
    }

    .c02_content {
      flex-direction: column;
    }
  }
`;
const Container03 = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 100px 0px 0px 0px;

  .c03_container {
    display: flex;
    // margin-bottom: 100px;

    .c03_l {
      flex: 1;
      flex-direction: column;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 670px;
      overflow: hidden;

      .c03_l_t_box {
        width: 80%;
        margin: auto;
      }
      .c03_l_t1 {
        text-align: left;
        font-size: ${fontSizeXXXXL};
        color: ${textColorTheme};
        font-weight: 900;
        margin-bottom: 30px;
      }
      .c03_l_t2 {
        text-align: left;
        font-size: ${fontSizeXXXXL};
        color: ${textColorMain};
        font-weight: 900;
        margin-bottom: 30px;
      }
      .c03_l_t3 {
        text-align: left;
        font-size: ${fontSize_16};
        color: ${textColorMain};
        line-height: 25px;
        margin-bottom: 50px;
      }
      .c03_l_btn_box {
        text-align: left;
      }
    }

    .c03_r {
      display: flex;
      flex: 1;
      text-align: center;
      height: 670px;
      overflow: hidden;
      justify-content: center;
      align-items: center;

      img {
        max-width: 90%;
      }
    }
  }
  .c03_arrow_box {
    text-align: center;
    margin-bottom: 50px;

    img {
      cursor: pointer;
    }
    img:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 800px) {
    padding-top: 50px;
    padding-bottom: 0px;

    .c03_container {
      flex-direction: column;

      .c03_l {
        .c03_l_t1 {
          text-align: center;
          margin-top: 30px;
        }
        .c03_l_t2 {
          font-size: ${fontSizeXXXL};
          text-align: center;
        }
        .c03_l_t3 {
          text-align: center;
        }
        .c03_l_t4 {
          text-align: center;
        }
        .c03_l_btn_box {
          text-align: center;
        }
      }
    }

    .c03_container.c03_column_reverse {
      flex-direction: column-reverse;
    }
  }
`;
const Container04 = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 100px 0px 0px 0px;

  .c04_container {
    text-align: center;
    // margin-bottom: 100px;

    .c04_icon_box {
      text-align: center;
    }
    .c04_title {
      font-size: ${fontSizeXXXXL};
      color: ${textColorMain};
      font-weight: 900;
      margin-bottom: 30px;
      text-align: center;
    }
    .c04_description {
      font-size: ${fontSize_16};
      color: ${textColorMain};
      text-align: center;
      line-height: 25px;
      margin-bottom: 50px;
    }
    .c04_input_box {
      margin-bottom: 50px;

      img {
        cursor: pointer;
        max-width: 100%;
      }
    }
    .c04_bottom_box {
    }
  }
  .c04_arrow_box {
    text-align: center;
    margin-bottom: 50px;

    img {
      cursor: pointer;
    }
    img:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 800px) {
    padding-top: 50px;
    padding-bottom: 0px;
    padding-right: 20px;
    padding-left: 20px;

    .c04_container {
      .c04_title {
        font-size: ${fontSizeXXXL};
      }
    }
  }
`;
const Container05 = styled.div`
  max-width: 1100px;
  margin: auto;
  text-align: center;
  margin-top: 280px;

  img {
    width: 100%;
  }
  .title {
    font-weight: 900;
    font-size: ${fontSize_40};
  }
  .title > div > span {
    color: ${textColorTheme};
  }

  .c05_bottom_box {
    margin-top: 50px;
  }
  .text:before {
    content: '셀러가 만든, 셀러를 위한';
  }

  .text2:before {
    content: '지금 무료로 체험해보세요!';
  }
  @media (max-width: 800px) {
    padding-right: 20px;
    padding-left: 20px;

    .text:before {
      text-align: center;
      content: '셀러가 만든,\\A셀러를 위한';
      white-space: pre;
    }

    .text2:before {
      text-align: center;
      content: '지금 무료로,\\A체험해보세요!';
      white-space: pre;
    }
  }
`;

const TitleBigText = styled.div`
  font-size: ${fontSizeXXXXL};
  color: ${textColorMain};
  text-align: center;
  font-weight: 900;

  span {
    color: ${textColorTheme};
  }

  .second_title {
    color: ${textLightYellow};
  }
`;

const DescriptionText = styled.div`
  font-size: ${fontSizeL};
  color: ${textColorMain};
  text-align: center;
  margin-bottom: 50px;
  // line-height: 40px;

  span {
    color: ${textColorTheme};
  }
`;

const BtnBox = styled.div`
  text-align: center;
`;

const BtnStyle = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  color: ${textColorTheme};
  font-size: ${fontSize_16};
  font-weight: 500;
  border: 0px;
  cursor: pointer;
  margin: auto;

  background-image: url('/asset/image/survey/answer_bg.jpg');
  background-repeat: repeat-x;
  background-size: 1px 101%;
  color: ${textColorFFF};

  :hover {
    opacity: 0.8;
  }

  div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-image: url('/asset/image/arrow/arrow_right_02.svg');
    background-repeat: no-repeat;
    background-position: 96% center;
  }
`;

const Main01Item = styled.div`
text-align: center;
height: 220px;

.main02_title {
    // height: 140px;

    img {
        // max-width: 90%;
        max-height: 70px;
    }
}
.main02_sub_title {
    color ${textColorFFF};
    font-size: ${fontSizeL};
    margin-bottom: 10px;
    font-weight: 900;
    margin-top:30px;
}

.main02_description {
    color : ${textLightYellow};
    font-size: ${fontSizeL};
    margin-bottom: 10px;
    span {
        color ${textColorFFF};
    }
}

@media(max-width: 1199.98px) {
    margin-bottom: 50px;

    .main02_title {
        height: 100px;

        img {
            max-height: 60%;
        }
    }
}
`;

const MainIndex = () => {
  const classes = useStyles();
  return (
    <>
      {/* <BackgroundTop />
            <BackgroundBlock /> */}

      <div className='first-section'>
        <Container01>
          <TitleBigText className=''>셀러님을 위한 <br /> <span>AI 이커머스</span></TitleBigText>
          <TitleBigText style={{ marginBottom: 30 }}>
            <span>어시스턴트 서비스</span>
          </TitleBigText>
          <DescriptionText>
            마켓위자드를 통해 지금 <span>셀링 스타일</span> 진단받고
            <br />
            AI가 추천하는 맞춤 아이템을 매일 받아보세요!
          </DescriptionText>
          <BtnBox>
            <Link href='/survey'>
              <BtnStyle style={{ width: 300 }}>
                <div>셀링 스타일 진단</div>
              </BtnStyle>
            </Link>
          </BtnBox>
        </Container01>
        <img className='left-img' src='/asset/image/main/main_ig_01.png' />
        <img className='right-img' src='/asset/image/main/main_ig_02.png' />
      </div>

      <Container02>
        <div className='content'>
          <div className='section2_title'>
            <TitleBigText style={{ color: textColorFFF }}>
              <span className='second_title'>AI가 추천</span>하는 
            </TitleBigText>
            <TitleBigText style={{ color: textColorFFF, marginBottom: 50 }}>
              <span className='second_title'>맞춤 아이템</span>을 매일 받아보세요!
              {/* <span className='second_title'>AI니까 가능</span>합니다! */}
            </TitleBigText>
          </div>
          <DescriptionText style={{ color: textColorFFF, marginBottom: 100 }}>
            어떤 물건을 판매해야할지 고민하고 계신가요?
            <br />
            마켓위자드는 230만개 이상의 아이템 중에서 셀러님에게 꼭 맞는 아이템을 추천드립니다. <br />
            잘나가는 셀러들의 판단 기준을 학습한 마켓위자드에게 스마트한 아이템 발굴을 맡겨주세요!
          </DescriptionText>

          <div className='c02_content'>
            <div>
              <Main01Item>
                <div className='main02_title'>
                  <img src='/asset/image/main/section_02_ig_01.png' />
                </div>
                <div className='main02_sub_title'>
                  + 30억건의 이커머스 데이터
                </div>
                <div className='main02_description'>
                  시장의 '<span>모든</span>' 정보를 수집합니다
                </div>
              </Main01Item>
            </div>
            <div>
              <Main01Item>
                <div className='main02_title'>
                  <img src='/asset/image/main/section_02_ig_02.png' />
                </div>
                <div className='main02_sub_title'>70개 이상의 분석지표</div>
                <div className='main02_description'>
                  셀러에게 꼭 '<span>필요한</span>' 데이터만 제공합니다.
                </div>
              </Main01Item>
            </div>
            <div>
              <Main01Item>
                <div className='main02_title'>
                  <img src='/asset/image/main/section_02_ig_03.png' />
                </div>
                <div className='main02_sub_title'>AI의 정교한 예측</div>
                <div className='main02_description'>
                  '<span>정확한</span>' 데이터를 빠르게 제공합니다.
                </div>
              </Main01Item>
            </div>
          </div>

          {/* <div className="down_btn">
                    <img src="/asset/image/arrow/arrow_down_02.svg" />
                </div> */}
        </div>
      </Container02>

      <Container03>
        <div className='c03_container c03_column_reverse'>
          <div className='c03_l'>
            <div className='c03_l_t_box'>
              <div className='c03_l_t1 number'>01</div>
              <div className='c03_l_t2'>WEEKLY <br /> 아이템 추천</div>
              <div className='c03_l_t3'>
                마켓위자드가 직접 선정한 판매하기 좋은 아이템! 
                <br />
                매주 카카오톡으로 발송되는 아이템 분석 리포트를 통해
                <br />
                시장 트렌드 파악은 물론 셀러님만의 판매 전략을 세워보세요!
              </div>
              <div className='c03_l_btn_box'>
                <Link href='/weekly/recommend'>
                  <BtnStyle style={{ width: 250 }}>
                    <div>WEEKLY 아이템 확인</div>
                  </BtnStyle>
                </Link>
              </div>
            </div>
          </div>
          <div className='c03_r'>
            <img src='/asset/image/main/section_04_main.png' />
          </div>
        </div>
        {/* <div className="c03_arrow_box">
                    <img src="/asset/image/arrow/arrow_down_03.svg" />
                </div> */}
      </Container03>

      <Container03>
        <div className='c03_container'>
          <div className='c03_r'>
            <img src='/asset/image/main/section_03_main.png' />
          </div>
          <div className='c03_l'>
            <div className='c03_l_t_box'>
              <div className='c03_l_t1 number'>02</div>
              <div className='c03_l_t2'>셀링 스타일 진단</div>
              <div className='c03_l_t3'>
                단 30초의 시간으로 알 수 있는 나의 판매 스타일!
                <br />
                AI가 진단한 셀러님의 셀링 스타일을 무료로 확인하고, 
                <br />
                셀러님의 맞춤 아이템을 즉시 추천받아보세요!
              </div>
              <div className='c03_l_btn_box'>
                <Link href='/survey'>
                  <BtnStyle style={{ width: 250 }}>
                    <div>셀링 스타일 진단</div>
                  </BtnStyle>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="c03_arrow_box">
                    <img src="/asset/image/arrow/arrow_down_03.svg" />
                </div> */}
      </Container03>

      <Container03>
        <div className='c03_container c03_column_reverse'>
          <div className='c03_l'>
            <div className='c03_l_t_box'>
              <div className='c03_l_t1 number'>03</div>
              <div className='c03_l_t2'>
                맞춤 아이템 추천
              </div>
              <div className='c03_l_t3'>
                감으로 찾고 혼자 고민하던 아이템 발굴,
                <br />
                AI 어시스턴트 마켓위자드가 대신합니다!
                <br />
                셀러님의 셀링 스타일에 딱 맞는 맞춤 아이템부터
                <br />
                AI가 예측한 매출 상승 가능성이 높은 아이템까지 
                <br />
                매일 추천해드립니다!
              </div>
              <div className='c03_l_btn_box'>
                <Link href='/survey'>
                  <BtnStyle style={{ width: 250 }}>
                    <div>맞춤 아이템 확인</div>
                  </BtnStyle>
                </Link>
              </div>
            </div>
          </div>
          <div className='c03_r'>
            <img src='/asset/image/main/section_05_main.png' />
          </div>
        </div>
        {/* <div className="c03_arrow_box">
                    <img src="/asset/image/arrow/arrow_down_03.svg" />
                </div> */}
      </Container03>

      <Container04>
        <div className='c04_container'>
          <div className='c04_icon_box'>
            <img src='/asset/image/main/main_icon_01.svg' />
          </div>
          <div className='c04_title'>아이템 분석 의뢰</div>
          <div className='c04_description'>
            아이템을 소싱하기 전 혼자서 고민하고 계신가요?
            <br />
            언제든지 마켓위자드에 아이템 분석을 의뢰해주세요!
            <br />
            최소한의 확신이 필요한 셀러님의 든든한 동반자가 되겠습니다.
          </div>
          <div className='c04_input_box'>
            <Link href='/marketresearch'>
              <img src='/asset/image/main/main_input_img.svg' />
            </Link>
          </div>
          <div className='c04_bottom_box'>
            <Link href='/marketresearch'>
              <BtnStyle style={{ width: 250 }}>아이템 분석 의뢰하기</BtnStyle>
            </Link>
          </div>
        </div>
        {/* <div className="c04_arrow_box">
                    <img src="/asset/image/arrow/arrow_down_03.svg" />
                </div> */}
      </Container04>

      <Container05>
        <div className='title'>
          <div>
            <span className='text'></span> 서비스
          </div>
          <div className='text2'></div>
        </div>
        <div className='c05_bottom_box'>
          <Link href='/survey'>
            <BtnStyle style={{ width: 250 }}>셀링 스타일 진단</BtnStyle>
          </Link>
        </div>
        <img src='/asset/image/main/section_07_main.png' />
      </Container05>
    </>
  );
};

export default MainIndex;
